import React from "react";
import { graphql } from "gatsby";
import PageWithCmsWrapper from "../../components/shared/page-with-cms-wrapper";

const Design = ({
                     path,
                     data: {
                         strapiFeaturesDesign: {
                           Title,
                           Content,
                           SeoTitle,
                           MetaDescriptions,
                         },
                     },
                 }) => {
    return (
        <PageWithCmsWrapper
            path={path}
            title={Title}
            content={Content}
            seoProps={{ title: SeoTitle, description: MetaDescriptions}}
        />
    );
};


export default Design;

export const query = graphql`
  query FeaturesDesignQuery {
    strapiFeaturesDesign {
      Title
      Content
      SeoTitle
      MetaDescriptions
    }
  }
`;
